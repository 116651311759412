import { requestApi } from "../../config/apiHandler";

export const verbalUploadStart = () => {
  return {
    type: "VERBAL_UPLOAD_STARTED",
  };
};

export const verbalUpload = (queries) => {
  return async (dispatch) => {
    dispatch(verbalUploadStart());
    let queries_uploaded = 0;
    let feature_id = await localStorage.getItem("feature_id");
    let queries_to_be_uploaded = queries.filter((f) => f.query.length !== 0);
    let first_empty_query = queries.find((f) => f.query.length === 0);
    let empty_query_index = queries.indexOf(first_empty_query);
    if (queries_to_be_uploaded.length > 0 && empty_query_index < 0) {
      queries_to_be_uploaded.forEach(async (query, i, arr) => {
        let formdata = {
          feature_id: feature_id,
          text: query.query,
        };

        let response = await requestApi("/trainings", "POST", formdata);

        if (response.code == "success") {
          queries_uploaded++;
          if (queries_uploaded === arr.length) {
            await localStorage.removeItem("feature_id");
            dispatch({ type: "VERBAL_UPLOAD_FINISHED" });
          }
        } else {
          dispatch(uploadError(response.message));
        }
      });
    } else {
      return dispatch({
        type: "EMPTY_FIELDS_ERROR",
        payload: {
          message: "Query Fields cannot be empty",
          index: empty_query_index,
        },
      });
    }
  };
};

export const uploadError = (msg) => {
  return {
    type: "UPLOAD_ERROR",
    payload: msg,
  };
};

export const closeModal = () => {
  return {
    type: "CLOSE_MODAL",
  };
};

export const dismissError = () => {
  return {
    type: "DISMISS_ERROR",
  };
};
