import { requestApi } from "../../config/apiHandler";
import { signOut } from "../actions/auth";

export const fetchFeaturesStart = () => {
  return {
    type: "FETCH_FEATURES_START",
  };
};

const compare = (a, b) => {
  if (a.label > b.label) {
    return -1;
  }
  if (a.label < b.label) {
    return 1;
  }
  return 0;
};

const getVariantId = (modelId) => {
  let variantId;

  if (modelId <= 5) {
    variantId = modelId;
  } else {
    variantId = modelId + 21;
  }
  console.log(variantId);
  return variantId;
};

export const fetchFeatures = (model) => {
  return async (dispatch) => {
    dispatch(fetchFeaturesStart());
    const makeId = 1,
      modelId = model,
      variantId = getVariantId(Number(model)); // need to define the variant ids
    let response = await requestApi(
      `/features?make_id=1&variant_id=${variantId}&model_id=${modelId}`,
      "GET",
      {}
    );

    if (response.code == "success") {
      response.data.features = response.data.features.filter(
        (feature) => feature.active === 1
      );

      const partIds = Array.from(
        new Set(
          response.data.features
            .filter(
              (feature) =>
                feature.make_id === Number(makeId) &&
                feature.model_id === Number(modelId) &&
                feature.variant_id === Number(variantId)
            )
            .map((feature) => feature.part_id)
        )
      );

      const parts = [];

      partIds.forEach((partId) => {
        const part = {
          feature_id: response.data.features.find(
            (feature) => feature.part_id === partId
          ).id,
          model: response.data.features.find(
            (feature) =>
              feature.make_id === Number(makeId) &&
              feature.model_id === Number(modelId)
          ).model,
          model_id: response.data.features.find(
            (feature) =>
              feature.make_id === Number(makeId) &&
              feature.model_id === Number(modelId)
          ).model_id,
          value: partId,
          label: response.data.features.find(
            (feature) => feature.part_id === partId
          ).part,
        };

        parts.push(part);
      });

      parts.sort(compare);
      return dispatch({
        type: "FETCH_FEATURES_SUCCESS",
        payload: {
          features: parts,
        },
      });
    } else if (response.code == "authn_fail") {
      dispatch(signOut());
    } else {
      dispatch(fetchFeaturesError(response.message));
    }
  };
};

export const fetchFeatureByPart = (id) => {
  return async (dispatch, getState) => {
    let model_id = await localStorage.getItem("model_id");

    // const response = await requestApi(
    //   `/trainings/stats?make_id=1&variant_id=${model_id}&model_id=${model_id}&part_id=${id}`,
    //   "GET",
    //   {}
    // );
    const state = getState();

    const feature = state.feature.featuresData.find(
      (feature) =>
        feature.value === id && Number(feature.model_id) === Number(model_id)
    );
    if (feature && feature.feature_id) {
      await localStorage.setItem(
        "feature_id",
        JSON.stringify(feature.feature_id)
      );
    } else {
      dispatch(signOut());
    }
  };
};

export const fetchFeaturesError = (msg) => {
  return {
    type: "FETCH_FEATURES_ERROR",
    payload: msg,
  };
};

export const dismissError = () => {
  return {
    type: "DISMISS_ERROR_FEATURE",
  };
};

export const fetchMetaData = () => {
  return async (dispatch) => {
    dispatch(fetchMetaDataStart());

    const response = await requestApi("/meta", "GET", {});
    if (response.code == "success") {
      // await AsyncStorage.setItem(
      //   'model_count',
      //   JSON.stringify(response.data.metadata.targets.model),
      // );
      // await AsyncStorage.setItem(
      //   'feature_count',
      //   JSON.stringify(response.data.metadata.targets.feature),
      // );
      return dispatch({
        type: "FETCH_METADATA",
        payload: response.data.metadata.targets,
      });
    } else {
      dispatch(signOut());
    }
  };
};

const fetchMetaDataStart = () => {
  return {
    type: "FETCH_METADATA_START",
  };
};

export const hideTutorial = () => {
  return {
    type: "HIDE_TUTORIAL_FEATURES",
  };
};
