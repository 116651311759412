import React, { useState } from "react";
import {
  Row,
  FormGroup,
  Col,
  Input,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Spinner,
} from "reactstrap";
import Select from "react-select";
import Navbar from "../components/Navbar";
import UploadAlert from "../components/Alerts/UploadAlert";
import { ErrorAlert } from "../components/Alerts/ErrorAlert";
import "./customStyles.css";
class VerbalForm extends React.Component {
  state = {
    inputList: [{ query: "", error: false }],
    model: null,
    feature: null,
    modelData: this.props.model.carsData,
    inputValue: "",
    featuresData: [],
    visible: false,
  };

  componentDidMount() {
    let token = localStorage.getItem("x-auth");
    if (token || this.props.auth.userToken) {
      this.props.history.push("/");
    } else {
      this.props.history.push("/login");
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.auth !== prevProps.auth) {
      if (!this.props.auth.userToken) {
        this.props.history.push("/login");
      }
    }
    if (this.props.model.carsData !== prevProps.model.carsData) {
      this.setState({ modelData: this.props.model.carsData });
    }
  }

  showModal = () => {
    this.setState({ visible: true });
  };

  hideModal = () => {
    this.setState({
      visible: false,
      model: null,
      feature: null,
      featuresData: [],
      inputList: [{ query: "" }],
    });
  };

  handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...this.state.inputList];
    list[index][name] = value;
    this.setState({ inputList: list });
  };

  // handle click event of the Add button
  handleAddClick = () => {
    this.setState({ inputList: [...this.state.inputList, { query: "" }] });
  };

  onModelChange = (value) => {
    this.setState(
      { featuresData: [], feature: null, model: value },
      async () => {
        await localStorage.setItem("model_id", this.state.model.value);
        await this.props.fetchFeatures(this.state.model.value);
        this.setState({ featuresData: this.props.feature.featuresData });
      }
    );
  };

  onFeatureChange = (value) => {
    this.setState({ feature: value, inputValue: "" }, async () => {
      await this.props.fetchFeatureByPart(this.state.feature.value);
    });
  };

  customFilter(option, searchText) {
    if (option.data.label.toLowerCase().includes(searchText.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  }

  handleInputFeatureChange = (inputValue) => {
    this.setState({ inputValue });
  };

  handleSubmit = async () => {
    if (!this.props.verbal.error) {
      this.showModal();
    }
    await this.props.verbalUpload(this.state.inputList);
  };

  hideError = () => {
    this.props.dismissError();
    let queries = this.state.inputList;
    queries.map((query, i) => {
      if (this.props.verbal.empty_index === i) {
        query.error = "has-danger";
      } else {
        query.error = "has-success";
      }
    });
    this.setState({ visible: false, inputList: queries });
  };

  hideModelError = () => {
    this.props.dismissErrorModel();
  };

  render() {
    return (
      <div className="wrapper" style={{ overflowX: "hidden" }}>
        <Navbar history={this.props.history} />
        <div className="content">
          {this.props.model.loading ? (
            <div
              className="content"
              style={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                height: "90vh",
              }}
            >
              <Spinner size="md" />
            </div>
          ) : (
            <Row className="mt-5">
              <Col lg="12">
                <Card
                  className="ml-auto mr-auto"
                  style={{ width: "80%", backgroundColor: "#232324" }}
                >
                  <CardHeader>
                    <CardTitle tag="h4">Questions</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row style={{ justifyContent: "center", padding: 5 }}>
                      <Col md="5">
                        <label>Model</label>
                        <FormGroup>
                          <Select
                            className="react-select info"
                            classNamePrefix="react-select"
                            name="singleSelect"
                            value={this.state.model}
                            onChange={(e) => this.onModelChange(e)}
                            options={this.state.modelData}
                            placeholder="Select Model"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="5">
                        <label>Feature</label>
                        <FormGroup>
                          <Select
                            className="react-select info"
                            classNamePrefix="react-select"
                            name="singleSelect"
                            value={this.state.feature}
                            onChange={(e) => this.onFeatureChange(e)}
                            options={this.state.featuresData}
                            placeholder="Select Feature"
                            inputValue={this.state.inputValue}
                            onInputChange={this.handleInputFeatureChange}
                            filterOption={this.customFilter}
                            styles={{
                              input: (provided, state) => {
                                return {
                                  color: "white",
                                  padding: 3,
                                };
                              },
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row style={{ justifyContent: "center", padding: 5 }}>
                      {this.state.inputList.map((x, i) => {
                        return (
                          <Col md="10" key={`Query-${i}`}>
                            <label>Query {i + 1}</label>
                            <FormGroup className={`has-label ${x.error}`}>
                              <Input
                                type="text"
                                name="query"
                                value={x.query}
                                onChange={(e) => this.handleInputChange(e, i)}
                                placeholder="Enter your text here"
                              />
                              {x.error === "has-danger" ? (
                                <label className="error">
                                  This field is required.
                                </label>
                              ) : null}
                            </FormGroup>
                            <div>
                              {this.state.inputList.length - 1 === i && (
                                <Button
                                  className="btn-light btn-light:hover"
                                  color="success"
                                  onClick={this.handleAddClick}
                                >
                                  Add more
                                </Button>
                              )}
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                    <Row
                      style={{ justifyContent: "center", padding: 5 }}
                      className="mt-5"
                    >
                      <Button
                        className="btn-light btn-light:hover"
                        color="success"
                        onClick={this.handleSubmit}
                      >
                        Submit
                      </Button>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
        {this.state.visible && (
          <UploadAlert
            uploading={this.props.verbal.uploading}
            hideModal={() => this.hideModal()}
          />
        )}
        {this.props.verbal.error && (
          <ErrorAlert
            message={this.props.verbal.error_msg}
            hide={() => this.hideError()}
          />
        )}
        {this.props.model.error && (
          <ErrorAlert
            message={this.props.model.error_msg}
            hide={() => this.hideError()}
          />
        )}
      </div>
    );
  }
}
export default VerbalForm;
