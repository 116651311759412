/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';

// reactstrap components
import {
	Button,
	Collapse,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	UncontrolledDropdown,
	Input,
	InputGroup,
	NavbarBrand,
	Navbar,
	NavLink,
	Nav,
	Container,
	Modal,
	UncontrolledTooltip,
} from 'reactstrap';

import { connect } from 'react-redux';
import { signOut } from '../store/actions/auth';
import Xane_A from '../assets/img/xane-icon.png';
import Suzuki_S from '../assets/img/suzuki_s.png';

class AdminNavbar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			collapseOpen: false,
			modalSearch: false,
			color: 'navbar-transparent',
		};
	}
	componentDidMount() {
		window.addEventListener('resize', this.updateColor);
	}
	componentWillUnmount() {
		window.removeEventListener('resize', this.updateColor);
	}
	// function that adds color white/transparent to the navbar on resize (this is for the collapse)
	updateColor = () => {
		if (window.innerWidth < 993 && this.state.collapseOpen) {
			this.setState({
				color: 'bg-white',
			});
		} else {
			this.setState({
				color: 'navbar-transparent',
			});
		}
	};
	// this function opens and closes the collapse on small devices
	toggleCollapse = () => {
		if (this.state.collapseOpen) {
			this.setState({
				color: 'navbar-transparent',
			});
		} else {
			this.setState({
				color: 'bg-white',
			});
		}
		this.setState({
			collapseOpen: !this.state.collapseOpen,
		});
	};
	// this function is to open the Search modal
	toggleModalSearch = () => {
		this.setState({
			modalSearch: !this.state.modalSearch,
		});
	};
	render() {
		const { pathname } = this.props.history.location;
		return (
			<>
				<Navbar expand="lg" style={{ backgroundColor: '#303030' }}>
					<Container fluid>
						<div className="navbar-wrapper">
							<NavbarBrand href="#pablo" onClick={(e) => e.preventDefault()}>
								<p style={{ fontWeight: 'bold' }}>
									{window.screen.width > 450 && <img src={Suzuki_S} width={24} />}{' '}
									<span style={{ marginLeft: 5 }}>TDC VERBAL</span>
								</p>
							</NavbarBrand>
						</div>
						<button
							className="navbar-toggler"
							type="button"
							data-toggle="collapse"
							data-target="#navigation"
							aria-expanded="false"
							aria-label="Toggle navigation"
							onClick={this.toggleCollapse}
						>
							<span className="navbar-toggler-bar navbar-kebab" />
							<span className="navbar-toggler-bar navbar-kebab" />
							<span className="navbar-toggler-bar navbar-kebab" />
						</button>
						<Collapse navbar isOpen={this.state.collapseOpen}>
							<Nav className="ml-auto" navbar>
								<UncontrolledDropdown nav>
									<DropdownToggle
                    caret
                    
										color="default"
										data-toggle="dropdown"
										nav
										onClick={(e) => e.preventDefault()}
									>
										<i class="fa fa-caret-down" aria-hidden="true"></i>
										<p className="d-lg-none">Menu</p>
									</DropdownToggle>
									<DropdownMenu className="dropdown-navbar" right tag="ul">
										<NavLink tag="li">
											<DropdownItem
												onClick={
													pathname === '/'
														? () => this.props.history.push('/activity')
														: () => this.props.history.push('/')
												}
												className="nav-item"
												style={{color:"black"}}
											>
												{pathname === '/' ? 'View Activity' : 'Upload Form'}
											</DropdownItem>
										</NavLink>
										<DropdownItem divider tag="li" />
										<NavLink tag="li">
											<DropdownItem style={{color:"black"}} onClick={() => this.props.signOut()} className="nav-item">
												Log out
											</DropdownItem>
										</NavLink>
									</DropdownMenu>
								</UncontrolledDropdown>
								
							</Nav>
						</Collapse>
					</Container>
				</Navbar>
				
			</>
		);
	}
}

const mapDispatchToProps = (dispatch) => ({
	signOut: () => dispatch(signOut()),
});

export default connect(null, mapDispatchToProps)(AdminNavbar);
