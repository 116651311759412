const initialState = {
  error: false,
  error_msg: null,
  carsData: [],
  //selectedModels: [],
  loading: true,
};

export const modelReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_MODELS_START":
      return {
        ...state,
        loading: true,
      };
    case "FETCH_MODELS_SUCCESS":
      return {
        ...state,
        carsData: action.payload.cars,
        //selectedModels: action.payload.cardData,
        loading: false,
      };
    case "FETCH_MODELS_ERROR":
      return {
        ...state,
        error: true,
        error_msg: action.payload,
        loading: false,
      };

    case "DISMISS_ERROR_MODEL":
      return {
        ...state,
        error: false,
        error_msg: null,
      };

    default:
      return state;
  }
};
