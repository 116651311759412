import React from "react";
import Activity from "../views/Activity";
import { connect } from "react-redux";
import { fetchActivity, dismissError } from "../store/actions/activity";
const ActivityContainer = (props) => {
  return (
    <div className="content">
      <Activity {...props} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  activity: state.activity,
  auth:state.auth
});

const mapDispatchToProps = (dispatch) => ({
  fetchActivity: () => dispatch(fetchActivity()),
  dismissError: () => dispatch(dismissError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivityContainer);
