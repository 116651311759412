import React, { useEffect } from "react";
import { connect } from "react-redux";
import { restoreToken } from "../store/actions/auth";
import { Switch, Route } from "react-router-dom";
import LoginContainer from "./Login";
import VerbalFormContainer from "./VerbalFormContainer";
import ActivityContainer from "./ActivityContainer";

const AppContainer = (props) => {
  useEffect(() => {
    const fetchToken = async () => {
      let userToken = await localStorage.getItem("x-auth");
      try {
        userToken = JSON.parse(await localStorage.getItem("x-auth"))
          ? JSON.parse(await localStorage.getItem("x-auth"))
          : null;
        props.restoreToken(userToken);
        if (userToken == null) {
          props.hist.push("/login");
        }
      } catch (e) {
        // Restoring token failed
      }
    };
    fetchToken();
  }, []);
  return (
    <Switch>
      <Route path="/" exact component={VerbalFormContainer} />
      <Route path="/activity" exact component={ActivityContainer} />
      <Route path="/login" exact component={LoginContainer} />
    </Switch>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  restoreToken: (token) => dispatch(restoreToken(token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
