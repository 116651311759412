import { requestApi } from "../../config/apiHandler";
import { signOut } from "../actions/auth";

export const fetchActivityStart = () => {
  return {
    type: "FETCH_ACTIVITY_START",
  };
};

export const fetchActivity = () => {
  return async (dispatch) => {
    dispatch(fetchActivityStart());
    let logs = [];
    const result = await requestApi(
      "/trainings/history?content_type_id=1",
      "GET",
      {}
    );
    if (result.code == "success") {
      result.data.history.map((o) => {
        let obj = {};
        obj.time = o.date.split("T")[0];
        obj.title = o.model;
        obj.description = `${o.part} ${o.count}`;

        logs.push(obj);
      });

      var helper = {};
      var arr = logs.reduce(function (r, o) {
        var key = o.time + "-" + o.title;

        if (!helper[key]) {
          helper[key] = Object.assign({}, o); // create a copy of o
          r.push(helper[key]);
        } else {
          var description = `\n${o.description}`;
          helper[key].description += description;
        }

        return r;
      }, []);

      return dispatch({
        type: "FETCH_ACTIVITY_SUCCESS",
        payload: arr,
      });
    } else if (result.code == "authn_fail") {
      return dispatch(signOut());
    } else {
      return dispatch({
        type: "FETCH_ACTIVITY_FAILURE",
        payload: result.message,
      });
    }
  };
};

export const dismissError = () => {
  return {
    type: "DISMISS_ERROR_ACTIVITY",
  };
};
