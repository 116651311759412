const initialState = {
  error: false,
  error_msg: null,
  featuresData: [],
  loading: true,
  selectedFeaturelId: null,
  selectedFeatureLabel: null,
  feature_count: 0,
  hideTutorial:false
};

export const feature = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_FEATURES_START':
      return {
        ...state,
        loading: true,
      };
    case 'FETCH_FEATURES_SUCCESS':
      return {
        ...state,
        featuresData: action.payload.features,
        loading: false,
      };
    case 'FETCH_FEATURES_ERROR':
      return {
        ...state,
        error: true,
        error_msg: action.payload,
        loading: false,
      };
    case 'GET_SELECTED_FEATURE':
      return {
        ...state,
        selectedFeatureId: action.id,
        selectedFeatureLabel: action.name,
      };
    case 'DISMISS_ERROR_FEATURE':
      return {
        ...state,
        error: false,
        error_msg:null
      };
    case 'FETCH_METADATA_START':
      return {
        ...state,
        loading: true,
      };
    case 'FETCH_METADATA':
      return {
        ...state,
        feature_count: action.payload.feature,
        loading: false,
      };
    case 'HIDE_TUTORIAL_FEATURES':
      return {
        ...state,
        hideTutorial: true,
      };
    default:
      return state;
  }
};