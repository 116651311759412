import React from "react";
import { Badge } from "reactstrap";
const ActivityCard = ({ actitvity }) => {
  return (
    <li className="timeline-inverted">
      <div className="timeline-badge">{actitvity.time}</div>

      <div className="timeline-panel">
        <div className="timeline-heading">
          <Badge style={{ backgroundColor: "rgb(25, 25, 26)",color:'#fff' }}>
            {actitvity.title}
          </Badge>
        </div>
        <div className="timeline-body">
          <ul style={{}}>
            {actitvity.description.split("\n").map((m, i) => {
              return <li key={i}>{m}</li>;
            })}
          </ul>
        </div>
      </div>
    </li>
  );
};

export default ActivityCard;
