import React, { Component } from "react";
import { Col, Card, CardBody, Badge, Row, Spinner } from "reactstrap";
import Navbar from "../components/Navbar";
import ActivityCard from "../components/ActivityCard";
class Activity extends Component {
  async componentDidMount() {
    let token = await localStorage.getItem("x-auth");
    if (token || this.props.auth.userToken) {
      this.props.history.push("/activity");
    } else {
      this.props.history.push("/login");
    }
    this.props.fetchActivity();
  }

  async componentDidUpdate(prevProps) {
    if (this.props.auth !== prevProps.auth) {
      if (!this.props.auth.userToken) {
        this.props.history.push("/login");
      }
    }
  }

  render() {
    return (
      <div className="wrapper">
        <Navbar history={this.props.history} />
        {this.props.activity.isLoading ? (
          <div
            className="content"
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              height: "90vh",
            }}
          >
            <Spinner size="md" />
          </div>
        ) : (
          <Row style={{ justifyContent: "center" }} className="mt-5">
            <Col lg="10" md="8" sm="6" xs="6">
              <Card className="card-timeline card-plain">
                <CardBody>
                  <ul className="timeline timeline-simple">
                    {this.props.activity.data.map((d, i) => {
                      return <ActivityCard key={i} actitvity={d} />;
                    })}
                    {/* <li className="timeline-inverted">
                    <div className="timeline-badge danger">2020-12-03</div>

                    <div className="timeline-panel">
                      <div className="timeline-heading">
                        <Badge color="danger">Baleno</Badge>
                      </div>
                      <div className="timeline-body">
                        <ul style={{}}>
                          {[
                            "Airbag Light - 4",
                            "ABS - 5",
                            "Accelerator Pedal - 7",
                          ].map((m, i) => {
                            return <li key={i}>{m}</li>;
                          })}
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li className="timeline-inverted">
                    <div className="timeline-badge danger">2020-12-01</div>
                    <div className="timeline-panel">
                      <div className="timeline-heading">
                        <Badge color="danger">Ciaz</Badge>
                      </div>
                      <div className="timeline-body">
                        <ul style={{}}>
                          {[
                            "Airbag Light - 4",
                            "ABS - 5",
                            "Accelerator Pedal - 7",
                          ].map((m, i) => {
                            return <li key={i}>{m}</li>;
                          })}
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li className="timeline-inverted">
                    <div className="timeline-badge success">2020-12-09</div>
                    <div className="timeline-panel">
                      <div className="timeline-heading">
                        <Badge color="success">XL6</Badge>
                      </div>
                      <div className="timeline-body">
                        <ul style={{}}>
                          {[
                            "Airbag Light - 4",
                            "ABS - 5",
                            "Accelerator Pedal - 7",
                          ].map((m, i) => {
                            return <li key={i}>{m}</li>;
                          })}
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li className="timeline-inverted">
                    <div className="timeline-badge info">2020-12-01</div>
                    <div className="timeline-panel">
                      <div className="timeline-heading">
                        <Badge color="info">S-CROSS</Badge>
                      </div>
                      <div className="timeline-body">
                        <ul style={{}}>
                          {[
                            "Airbag Light - 4",
                            "ABS - 5",
                            "Accelerator Pedal - 7",
                          ].map((m, i) => {
                            return <li key={i}>{m}</li>;
                          })}
                        </ul>
                      </div>
                    </div>
                  </li> */}
                  </ul>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

export default Activity;
