import { requestApi } from "../../config/apiHandler";
import { signOut } from "../actions/auth";

export const fetchModelsStart = () => {
  return {
    type: "FETCH_MODELS_START",
  };
};

export const fetchModels = () => {
  return async (dispatch) => {
    dispatch(fetchModelsStart());

    let response = await requestApi("/features?make_id=1", "GET", {});
    if (response.code == "success") {
      response.data.features = response.data.features.filter(
        (feature) => feature.active === 1
      );
      //let modelData = response.data.stats.filter(model => model.count === 0);
      const models = [];
      const modelIds = Array.from(
        new Set(response.data.features.map((feature) => feature.model_id))
      );

      modelIds.forEach((modelId) => {
        const model = {
          value: modelId,
          label: response.data.features.find(
            (feature) => feature.model_id === modelId
          ).model,
        };

        models.push(model);
      });

      return dispatch({
        type: "FETCH_MODELS_SUCCESS",
        payload: {
          cars: models,
        },
      });
    } else if (response.code == "authn_fail") {
      dispatch(signOut());
    } else {
      dispatch(fetchModelsError(response.message));
    }
  };
};

export const fetchModelsError = (msg) => {
  return {
    type: "FETCH_MODELS_ERROR",
    payload: msg,
  };
};

export const dismissErrorModel = () => {
  return {
    type: "DISMISS_ERROR_MODEL",
  };
};
