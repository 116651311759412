import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";

import { verbal } from "./reducers/verbal";
import { authReducer } from "./reducers/auth";
import { feature } from "./reducers/feature";
import { activity } from "./reducers/activity";
import { modelReducer } from "./reducers/models";

const rootReducer = combineReducers({
  auth: authReducer,
  feature: feature,
  verbal: verbal,
  activity: activity,
  model: modelReducer,
});

const composeEnhancers =
  (process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null) || compose;

export const configureStore = () => {
  return createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
};
