import React, { useEffect } from "react";
import VerbalForm from "../views/VerbalForm";
import { connect } from "react-redux";
import { fetchFeatures, fetchFeatureByPart } from "../store/actions/feature";
import { dismissError, verbalUpload } from "../store/actions/verbal";
import { dismissErrorModel, fetchModels } from "../store/actions/models";
const VerbalFormContainer = (props) => {
  useEffect(() => {
    props.fetchModels();
  }, []);

  return <VerbalForm {...props} />;
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  feature: state.feature,
  verbal: state.verbal,
  model: state.model,
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchFeatures: (id) => dispatch(fetchFeatures(id)),
    fetchFeatureByPart: (id) => dispatch(fetchFeatureByPart(id)),
    verbalUpload: (data) => dispatch(verbalUpload(data)),
    dismissError: () => dispatch(dismissError()),
    fetchModels: () => dispatch(fetchModels()),
    dismissErrorModel: () => dispatch(dismissErrorModel()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerbalFormContainer);
