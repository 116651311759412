const INITIALSTATE = {
  visible: false,
  images: [],
  imagesCount: 0,
  uploaded: 0,
  uploading: false,
  error: false,
  error_msg: "",
  empty_index: null,
};

export const verbal = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case "VERBAL_UPLOAD_STARTED":
      return {
        ...state,
        uploading: true,
        visible: true,
      };

    case "VERBAL_UPLOAD_FINISHED":
      return {
        ...state,
        uploading: false,
        error: false,
      };
    case "CLOSE_MODAL":
      return {
        ...state,
        visible: false,
      };
    case "UPLOAD_ERROR":
      return {
        ...state,
        error: true,
        error_msg: action.payload,
        uploading: false,
      };
    case "DISMISS_ERROR":
      return {
        ...state,
        error: false,
        error_msg: null,
      };
    case "EMPTY_FIELDS_ERROR":
      return {
        ...state,
        error: true,
        error_msg: action.payload.message,
        uploading: false,
        empty_index: action.payload.index,
      };
    default:
      return state;
  }
};
